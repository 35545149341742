<template>
  <div class="DisassemblyDistribution">
    <div class="header">
      <span @click="previousPage"></span>
      <span>OA审核管理</span>
      <span></span>
    </div>
    <van-search
          v-model="value"
          show-action
          placeholder="请输入搜索关键词"
          @search="onSearch"
          :style="{marginTop:'50px'}"
          >
          <select v-model="label" slot="label">
              <option value="carNumberPlate">车牌号：</option>
              <option value="vin">车架号：</option>
              <option value="defined_num">自定义编号：</option>
          </select>

          <template #action>
              <div @click="onSearch">搜索</div>
          </template>
      </van-search>
    <van-pull-refresh class="refresh" v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="我是有底线的"
        :immediate-check="false"
        @load="onLoad"
        :offset="20"
      
      >
        <div
          class="Distribution_mains"
          v-for="(item, index) in tableData"
          @click="goDetail(item, index)"
          :class="{ bg: index == isactive }"
          :key="item.id"
        >
          <div class="main_right" >
            <div>
              <span>审批类型</span>
              <span class="characters">{{ item.TypeText }}</span>
              <!-- <input type="text" name="" id="" v-model="item.end_of_lifeVehiclesId" readonly="readonly"> -->
            </div>
            <div>
              <span>自定义编码</span>
              <span class="characters">{{ item.defined_num }}</span>
              <!-- <input type="text" name="" id="" v-model="item.end_of_lifeVehiclesId" readonly="readonly"> -->
            </div>
            

            <div>
              <span>车牌号</span>
              <span class="characters">{{ item.carNumberPlate }}</span>
              <!-- <input type="text"  v-model="item.carNumberPlate" readonly="readonly"> -->
            </div>
            <div>
              <span>车架号码</span>
              <span class="characters">{{ item.vin }}</span>
              <!-- <input type="text"  v-model="item.vehicleBrand" readonly="readonly"> -->
            </div>
            <div>
              <span>创建时间</span>
              <span class="characters">{{ item.createTime }}</span>
              <!-- <input type="text"  v-model="item.createTime" readonly="readonly"> -->
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <!-- <div></div> -->
  </div>
</template>
<script>
import { deleteCookie } from "../../utils/env";
import { encipherMent, encryptDecode } from "@/utils/encrypt";
export default {
  data() {
    return {
      checked: false,
      tableData: "",
      id: "",
      remark: "",
      isactive: 0, //默认第一个有样式
      ids: null,
      isLoading: false, //下拉刷新
      loading: false, // 上拉加载
      finished: false, // 上拉加载完毕
      page: 1, //页
      pageSize: 10, //每页大小
      tableData: [],
      value: "",
    
      label: "carNumberPlate",
    };
  },
  created() {
    this.lodData();
  },
  methods: {
    onSearch(){
      this.lodData();
    },  
    //下拉
    onLoad() {
      let this_ = this;
      setTimeout(() => {
        let page = this_.page + 1;
        this_.page = page;
        let pageSize = this_.pageSize;
 
        let data = encipherMent(JSON.stringify({ pageSize: pageSize,  [this.label]:this.value }));
        let param = new URLSearchParams();
        param.append("value", data);
        param.append("page", this_.page);
        this_.$http
          .post(
            "/index.php/approval/index",
            param
          )
          .then((res) => {
            let result = {};
            if (res.data.code == -1 || res.data.code == 9) {
              result = res.data;
            } else {
              result = JSON.parse(encryptDecode(res.data));
            }
            if (result.code == 0) {
              let arr = result.data.data;
              if(arr.length!=0){
                arr.forEach((element) => {
                  this_.tableData.push(element);
                });
                if (this_.tableData.length >= result.data.total) {
                  this.finished = true;
                } else {
                  this.finished = false;
                }
              }else{
                // alert(1)
                this_.finished = true;
                this_.isLoading = false;
              }
              this_.isLoading = false;
            }
          });
      }, 1000);
    },
    onRefresh() {
      // this.finished = false;
      this.lodData();
    },
    lodData() {
      this.page = 0
      this.tableData = []
      this.finished = true;
      this.isLoading = true;
      this.onLoad()
      // let data = encipherMent(JSON.stringify({ pageSize: 10 , [this.label]:this.value }));
      // let param = new URLSearchParams();
      // param.append("value", data);
      // param.append("page", 1);
      // this.$http
      //   .post("/index.php/approval/index", param)
      //   .then((res) => {
      //     let result = {};
      //     if (res.data.code == -1 || res.data.code == 9) {
      //       result = res.data;
      //     } else {
      //       result = JSON.parse(encryptDecode(res.data));
      //     }
      //     if (result.code == 0) {
      //       this.isLoading = false;
      //       if (result.data.data.length == 0) {
      //         this.finished = true;
      //         this.$toast.fail("无数据");
      //       //   this.$router.push("/guide");
      //         this.tableData=result.data.data;
      //       } else {
      //         this.finished = false;
      //         this.tableData = result.data.data;
      //         this.id = result.data.data[0].end_of_lifeVehiclesId;
      //         this.ids = result.data.data[0].id;
      //       }
      //     } else if (result.code == "-1") {
      //       this.$toast.fail(result.msg);
      //       this.$router.push("/login");
      //       deleteCookie("token");
            
      //     } else {
      //       this.$toast.fail(result.msg);
      //     }
      //   });
    },
    goDetail(item, index) {
      this.id = item.id;
      this.source_id = item.source_id;

      this.isactive = index;
      //console.log(index);
      this.$router.push({
        path:"/OA/detail",
        query: { id: item.id, source_id: item.source_id ,t:item.type},
      });
    },
    previousPage() {
      this.$router.push("/guide");
    },
  },
};
</script>
<style lang="scss" scope="this api replaced by slot-scope in 2.5.0+">
// .van-list__finished-text{
//   height: 60vh;
// }
.DisassemblyDistribution {
  height: 100%;
  .bg {
    // color: red;
    background-color: #eee;
  }
  .header {
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    position: fixed;
    top: 0;
    width: calc(100% - 40px);
    z-index: 9;
    justify-content: space-between;
    span {
      display: inline-block;
    }
    span:nth-child(1) {
      width: 30px;
      height: 30px;
      background-image: url(../../assets/u39.png);
      background-size: 100%;
      margin-top: 8px;
    }
  }
  .Distribution_mains {
    width: 90%;
    overflow: hidden;
    margin: 20px auto;
    border: 1px solid;
    padding: 0.3rem;
    // flex-wrap: nowrap;

    .main_left {
      width: 10%;
      height: 100%;

      // border: 1px solid;
      // float: left;
    }
    .main_right {
      width: 100%;

      // border: 1px solid;
      font-size: 0.35rem;
      // float: left;
      div {
        margin-top: 10px;
        border-bottom: 1px solid #e7e0e0f5;
        span {
          width: 2.5rem;
          display: inline-block;
          text-align: center;
          margin-right: 23px;
        }
        .characters {
          width: 60%;
          display: inline-block;
          text-align: center;
          padding: 3px;
        }
        input {
          width: 60%;
          height: 0.5rem;
        }
      }
    }
  }
  .header {
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    position: fixed;
    top: 0;
    width: calc(100% - 40px);
    z-index: 9;
    justify-content: space-between;
    span {
      display: inline-block;
    }
    span:nth-child(1) {
      width: 30px;
      height: 30px;
      background-image: url(../../assets/u39.png);
      background-size: 100%;
      margin-top: 8px;
    }
  }
}
</style>
